(() => {
  'use strict';

  // Fetch the contact form we want to apply custom Bootstrap validation styles to
  const form = document.getElementById('contactForm');

  if (form) {
    form.addEventListener('submit', (event) => {
      // Check validity using the built-in HTML5 validation
      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        event.preventDefault(); // Prevent the default form submission
        handleFormSubmission(form); // Call the function to handle form submission
      }

      form.classList.add('was-validated');
    }, false);
  }

  // Function to handle form submission
  const handleFormSubmission = () => {
    // Show loader in the button and disable the submit button
    const submitButton = document.getElementById('submitButton');
    submitButton.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...';
    submitButton.disabled = true;

    // Split full name into first and last name
    const fullName = document.getElementById('fullName').value.trim();
    const nameParts = fullName.split(' ');
    const firstName = nameParts[0] || '';
    const lastName = nameParts.slice(1).join(' ') || firstName; // Use the full name as last name if only one name is provided

    // Prepare data to send to the HubSpot API
    const data = {
      fields: [
        { name: 'firstname', value: firstName },
        { name: 'lastname', value: lastName },
        { name: 'email', value: document.getElementById('email').value },
        { name: 'industry_vertical', value: document.getElementById('industry').value },
        { name: 'what_are_you_looking_for_ems_offering', value: document.getElementById('interest').value },
        { name: 'pagetitle', value: document.title },
        { name: 'pageurl', value: window.location.href }
      ]
    };

    // Replace with your HubSpot API endpoint and key
    const hubspotUrl = 'https://api.hsforms.com/submissions/v3/integration/submit/3906991/f178cc5e-c315-480d-8a36-947f33be8d21';

    fetch(hubspotUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.status === 'error') {
          handleFormError(responseData);
        } else {
          showThankYouScreen();
        }
      })
      .catch((error) => {
        console.error('There was an error submitting the form:', error);
      // Handle other errors (e.g., network issues)
      })
      .finally(() => {
      // Re-enable the submit button for resubmission attempts
        submitButton.innerHTML = 'Talk to us';
        submitButton.disabled = false;
      });
  };

  // Function to show the thank you screen
  const showThankYouScreen = () => {
    document.getElementById('contactForm').classList.add('d-none');
    document.getElementById('talkToUsModalLabel').classList.add('d-none');
    document.getElementById('thankYouScreen').classList.remove('d-none');
  };

  // Function to handle form errors
  const handleFormError = (responseData) => {
    responseData.errors.forEach((error) => {
      if (error.errorType === 'BLOCKED_EMAIL') {
        const emailField = document.getElementById('email');
        emailField.classList.add('is-invalid');
        emailField.classList.remove('is-valid'); // Ensure valid class is removed when invalid
        const emailError = emailField.nextElementSibling;
        emailError.textContent = 'Please enter a valid business email to move forward';
      }
    });
  };

  // Clear the validation error when the email field is changed
  document.getElementById('email').addEventListener('input', function() {
    this.classList.remove('is-invalid');
    this.classList.remove('is-valid'); // Optional: Remove the valid class on input change
    const emailError = this.nextElementSibling;
    emailError.textContent = ''; // Clear the error message
  });

  // Reset the form validation state when the modal is closed
  const modal = document.getElementById('talkToUsModal');
  if (modal) {
    modal.addEventListener('hidden.bs.modal', () => {
      form.classList.remove('was-validated');
      form.reset();  // Reset the form fields
      const formElements = form.elements;
      for (let i = 0; i < formElements.length; i++) {
        formElements[i].classList.remove('is-invalid');
        formElements[i].classList.remove('is-valid'); // Remove valid class on reset
      }
      document.getElementById('submitButton').innerHTML = 'Talk to us';
      document.getElementById('thankYouScreen').classList.add('d-none');
      document.getElementById('contactForm').classList.remove('d-none');
      document.getElementById('talkToUsModalLabel').classList.remove('d-none');
    });
  }
})();